import React from 'react';
import styles from './index.module.css'
//a75c7996-5b6e-51b9-89ff-fd6d7dd7cd0b
export default function ListOfArticles(props){
let items = props.body[0]?.items;
let titleOfSection = props.body[0]?.primary?.title_of_section?.text;
let parentSlug = props.parentSlug;

if (items !== undefined) {
return (
  <div>
  <h2> {titleOfSection ? titleOfSection : 'Artiklar och recensioner'} </h2>
  {items.map((article) =>{
    return (
      <a href={"/" + parentSlug + article.articles_to_link.uid}>
      <div key={article} className={styles.card} >
      <h3>{article.header1.text}</h3>
      <div className={styles.cardFlexContent}>
        <p>{article.ingress}</p>
        {article.articles_to_link.document.data.productimage.url &&
          <img srcset={article.articles_to_link.document.data.productimage.fluid.srcSetWebp} sizes="(max-width: 480px) 100px,(max-width: 900px) 150px, 200px" src={article.articles_to_link.document.data.productimage.url} alt={article.articles_to_link.document.data.productimage.alt} />
        }
      </div>
      </div>
      </a>
    )
  })}
  </div>
)
} else {
  return null;
}
}
