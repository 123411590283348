import React from "react"
import Layout from '../components/layout';
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby';
import styles from '../components/index.module.css';
import Img from 'gatsby-image';
import ListOfArticles from '../components/ListOfArticles'

export default function CategoryPage({data}){

  const { prismicCategory } = data;
  const { title, body, metadescription, header, categoryimage, categoryintrotext, categorymaintext, disclaimertext } = prismicCategory.data;
  const uid = prismicCategory.uid + '/';
  return (
    <Layout>
    <Helmet>
    <meta name="description" content={metadescription} />
    <title> {title} </title>
    </Helmet>
    <div className={styles.productCategoryMainContainer}>
      <div className={ styles.productCategoryMainDescription }>
        <div className={ styles.productCategoryMainDescriptionHeader }>
          <div dangerouslySetInnerHTML={{__html: header.html}}/>
          <Img style={{'maxWidth': '800px'}} fluid={ categoryimage.fluid } alt={ categoryimage.alt }></Img>
          <div dangerouslySetInnerHTML={{__html: categoryintrotext.html}}/>
          <div dangerouslySetInnerHTML={{__html: categoryimage.html}}/>
          <div dangerouslySetInnerHTML={{__html: categorymaintext.html}} />

          <ListOfArticles body={body} parentSlug={uid}/>
        </div>
      </div>
      {disclaimertext && <div className={styles.sponsor} dangerouslySetInnerHTML={{__html: disclaimertext}}/>}
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query MyQuery($id: String!) {
  prismicCategory(id: {eq: $id}) {
    data {
      categoryimage {
        alt
        copyright
        url
      }
      body {
        ... on PrismicCategoryBodyListOfArticles {
          id
          items {
            ingress
            header1 {
              html
              text
            }
            articles_to_link {
              document {
                ... on PrismicProduct {
                  id
                  data {
                    productimage {
                      alt
                      copyright
                      url
                      fluid(maxHeight: 50) {
                        src
                        srcSetWebp
                      }
                    }
                  }
                }
              }
              uid
            }
          }
          primary {
            title_of_section {
              text
            }
          }
        }
      }
      categoryintrotext {
        html
        text
        raw
      }
      categorymaintext {
        html
        text
        raw
      }
      header {
        html
        raw
        text
      }
      title
      metadescription
      disclaimertext
    }
    uid
  }
  prismicCategoryBodyListOfArticles {
    id
  }
}
`
